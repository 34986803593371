<template>
  <v-app>
    <v-banner
      single-line
      height="500"
      class="banner"
    >
      <v-carousel class="carousel-new carousel-banner" height="500"
                  show-arrows-on-hover :cycle="true"
                  hide-delimiter-background :reverse="false"
                  :continuous="true">
        <v-carousel-item v-for="v in data.banner" :key="v.length">
          <v-img
            :src="v.bannerImg"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold" :style="'color: ' + v.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in v.title" :key="tipIndex" class="pt-2" style="font-weight: 500;">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + v.titleColor + '!important;'">
                  <div>
                    <div v-for="(tip,tipIndex) in v.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                  </div>
                </v-card-title>
                <v-card-actions class="pa-0 mt-10" v-if="v.btn">
                  <v-btn
                      rounded
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click.stop="handleBannerBtn(v)"
                  >
                    <span style="color: #2684EA">{{ v.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
        
      </v-carousel>
    </v-banner>
    <!-- 产品矩阵 -->
    <v-card class="warps warp1" :flat="true">
      <div class="public-title">
        <p>PRODUCT MATRIX</p>
        <div>
          <!-- <span>万界智慧营销平台-产品矩阵</span> -->
          <span>产品矩阵</span>
          <!-- <span>全经营场景覆盖，快速高效解决营销难题</span> -->
          <span>虚拟与现实完美融合</span>
        </div>
      </div>
      <div class="con" v-if="isIE">
        <img class="cpjz" src="https://h5.ophyer.cn/official_website/other/ftyd-home-cpjz.jpg" alt="">
      </div>
      <div class="con" v-else>
        <img class="con-img" src="@/assets/images/case/home-product-z1.png" />
        
        <ul>
          <li class="con1">
            <div>
              <p class="icon" @click="goLink()">
                <img src="@/assets/images/case/home-product01-icon.png" />
                <img src="@/assets/images/case/home-product01-k.png" />
                <span></span>
              </p>
              <p class="text">
                <img src="@/assets/images/case/home-product01-text.png" />
                <img src="@/assets/images/case/home-product-icon.png" />
              </p>
            </div>
            <div>
              <p class="icon" @click="$router.push('/serviceProducts/SaasCloud')">
                <img src="@/assets/images/case/home-product02-icon.png" />
                <img src="@/assets/images/case/home-product02-k.png" />
                <span></span>
              </p>
              <p class="text">
                <img src="@/assets/images/case/home-product02-text.png" />
                <img src="@/assets/images/case/home-product-icon.png" />
              </p>
            </div>
            <div>
              <p class="icon" @click="$router.push('/serviceProducts/LearnCloud')">
                <img src="@/assets/images/case/home-product03-icon.png" />
                <img src="@/assets/images/case/home-product03-k.png" />
                <span></span>
              </p>
              <p class="text">
                <img src="@/assets/images/case/home-product03-text.png" />
                <img src="@/assets/images/case/home-product-icon.png" />
              </p>
            </div>
          </li>
          <li class="con2">
            <div>
              <p class="icon" @click="$router.push('/serviceProducts/EfficiencyCloud')">
                <img src="@/assets/images/case/home-product04-icon.png" />
                <img src="@/assets/images/case/home-product06-k.png" />
                <span></span>
              </p>
              <p class="text">
                <img src="@/assets/images/case/home-product04-text.png" />
                <img src="@/assets/images/case/home-product-icon.png" />
              </p>
            </div>
            <div>
              <p class="icon" @click="$router.push('/serviceProducts/SceneCloud')">
                <img src="@/assets/images/case/home-product05-icon.png" />
                <img src="@/assets/images/case/home-product05-k.png" />
                <span></span>
              </p>
              <p class="text">
                <img src="@/assets/images/case/home-product05-text.png" />
                <img src="@/assets/images/case/home-product-icon.png" />
              </p>
            </div>
            <div>
              <p class="icon" @click="$router.push('/serviceProducts/WanqingCloud')">
                <img src="@/assets/images/case/home-product06-icon.png" />
                <img src="@/assets/images/case/home-product04-k.png" />
                <span></span>
              </p>
              <p class="text">
                <img src="@/assets/images/case/home-product06-text.png" />
                <img src="@/assets/images/case/home-product-icon.png" />
              </p>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="con">
        <v-row no-gutters justify='space-between'>
          <v-col class="px-4 mb-10"  v-for="(v, k) in data.warp1.list" :key="k" cols="6" sm="4" >
            <div class="box" @click="$router.push(v.router)">
              <img :src='v.img' />
              <div>
                <p>{{ v.title }}</p>
                <p>{{ v.text }}</p>
              </div>
            </div>
          </v-col>
        </v-row>
      </div> -->
    </v-card>
    <!-- 行业解决方案 -->
    <v-card class="warps warp2" :flat="true">
      <div class="public-title">
        <p>INDUSTRY SOLUTIONS</p>
        <div>
          <span>行业解决方案</span>
          <span>虚拟+数字，驱动业务指数级增长</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered  color="#0084ff" class="g-tabs">
        <v-tab v-for="(v, k) in data.warp2.list" :key="k">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.warp2.list" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k"><img :src='v.img' /></div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div class="right" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="tip" v-text="v.tip" />
                  <v-card-text class="text" v-for="(i, j) in v.text" :key="j" v-text="i" />
                </div>
              </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-card class="warps warp3" :flat="true" style="margin-bottom: 60px;">
      <div class="public-title">
        <p>CORE FEATURES</p>
        <div>
          <span>核心特点</span>
          <span>深耕AR/VR/AI领域数十年，赋能品牌数字化升级</span>
        </div>
      </div>
      <v-container fluid>
        <v-row no-gutters justify='space-around'>
          <v-col v-for="(v, k) in data.warp3.list" :key="k" cols="12" sm="3" >
            <div class="box" :class="'box'+v.id">
              <span>{{ v.title }}</span>
              <img :src='v.img' />
              <p>{{ v.text }}</p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <!--<v-card class="warps warp4" :flat="true" style="background-color: #f4f8fe">-->
    <!--  <div class="public-title">-->
    <!--    <p>PARTMER</p>-->
    <!--    <div>-->
    <!--      <span>合作伙伴</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <div class="box">-->
    <!--    <v-img-->
    <!--      src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png"-->
    <!--    />-->
    <!--  </div>-->
    <!--</v-card>-->
    <!--<v-card class="warps warp5" :flat="true">-->
    <!--  <div class="public-title">-->
    <!--    <p>INDUSTRY INFORMATION</p>-->
    <!--    <div>-->
    <!--      <span>行业资讯</span>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--  <div class="con">-->
    <!--    <v-carousel class="carousel-new" height="280" hide-delimiters :reverse="false" show-arrows-on-hover :cycle="true">-->
    <!--      <v-carousel-item-->
    <!--        v-for="(v, k) in data.warp5.list"-->
    <!--        :key="k"-->
    <!--      >-->
    <!--        <div class="box" @click="goCaseDetail(k)">-->
    <!--          <img :src="v.img" alt="" />-->
    <!--          <div>-->
    <!--            <p>{{ v.title }}</p>-->
    <!--            <p>{{ v.tip }}</p>-->
    <!--            <p>{{ v.text }}</p>-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </v-carousel-item>-->
    <!--    </v-carousel>-->
    <!--  </div>-->
    <!--</v-card>-->
  </v-app>
</template>

<script>
import textData from '@/static/textData.js'
import qs from 'qs'
// import { throttle } from '@/utils/utils'
import { browserVersion } from "@/utils/utils"

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data: textData.Home,
      tab: null,
      vm: this,
      // screenY: 0,
      // windowWidth: document.documentElement.clientWidth
      isIE: browserVersion()
    }
  },
  watch: {
    // screenY: {
    //   handler(val, olVal) {
    //     console.log(this.windowWidth)
    //     if(olVal > val) {
    //       console.log(val/olVal)
    //       console.log('right')
    //       this.$refs.bannerimg[0].$el.childNodes[1].style.transform = 'scale(1) translate('+val/olVal*10+'px, 0px) rotate(0deg)';
    //     } else {
    //       console.log(val/olVal)
    //       console.log('left')
    //       this.$refs.bannerimg[0].$el.childNodes[1].style.transform = 'scale(1) translate('+-val/olVal*10+'px, 0px) rotate(0deg)';
    //     }
    //   },
    //   deep: true,
    //   immediate: true
    // }
  },
  created() {
  },
  methods: {
    goCaseDetail(key) {
      let query = {
        source: 'home',
        key: key,
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
    handleBannerBtn(info) {
      if(info.router){
        window.open(window.location.protocol + "//" + window.location.host + info.router);
      }else if(info.link){
        window.open(info.link);
      }else {
        this.showDialog = true;
      }
    },
    goLink() {
      let domains = process.env.VUE_APP_NODE_ENV === "production" ? process.env.VUE_APP_PRODUCTION_DOMAIN : process.env.VUE_APP_TEST_DOMAIN;
      domains = domains.split(",");
      window.open(domains[1]);
    }
    // move: throttle(() => {
    // }, 500, 100),
  }
}
</script>

<style lang="scss" scoped>

.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .metaTitle{
    .font-size-banner-title{
      font-size: 80px;
      font-weight: bold;
      color: #FFFFFF !important;
      mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#FFFFFF), to(rgba(0, 0, 255, 0.5)));
    }
    .font-size-banner-tip{
      font-size: 28px;
      font-weight: 300;
      color: #FFFFFF !important;
      line-height: 36px;
      text-shadow: 0 6px 10px #000000;
      mask-image:-webkit-gradient(linear, 0 0, 0 bottom, from(#FFFFFF), to(rgba(0, 0, 255, 0.5)));
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}

.warps {
  margin: 0 auto;
  text-align: center;

}

.warp1 {
  width: 100%;
  // height: 720px;
  // background: url("https://h5.ophyer.cn/official_website/other/home-product-bg01.png");
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-size: 100%;
  .con {
    width: 1200px;
    height: 550px;
    margin: 5px auto;
    position: relative;
    // background-color: pink;
    .con-img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 6;
    }
    
    ul {
      width: 100%;
      position: relative;
    }
    .con1 {
      position: absolute;
      top: 50px;
      left: -50px;
      width: 50%;
      div {
        width: 500px;
        display: flex;
        justify-content: flex-end;
        position: relative;
        // background-color: #fff;
        .icon {
          position: absolute;
          z-index: 4;
          width: 196px;
          height: 174px;
          cursor: pointer;
          img:nth-child(1) {
            position: absolute;
            top: 1px;
            left: 9px;
            z-index: 3;
            width: 178px;
          }
          img:nth-child(2) {
            display: none;
            position: absolute;
            top: -13px;
            left: 0;
            z-index: 3;
          }
          span {
            position: absolute;
            right: -260px;
            top: 15px;
            z-index: 2;
            display: inline-block;
            width: 310px;
            height: 124px;
            background: #fff;
            pointer-events: none;
          }
          &:hover~.text {
            opacity: 1;
            position: absolute;
            right: 110px;
            img:nth-child(2) {
              opacity: 1;
              animation:move-two 3s 0s infinite;
            }
            &::after {
              right: 80px;
            }
          }
          &:hover {
            img:nth-child(2) {
              display: block;
            }
          }
        }
        .text {
          width: 426px;
          position: absolute;
          right: -239px;
          top: 21px;
          z-index: 2;
          transition: right 0.5s;
          opacity: 0;
          img:nth-child(1) {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
          }
          img:nth-child(2) {
            position: absolute;
            top: -55px;
            left: 0;
            z-index: 1;
            transform: rotate(-30deg);
            opacity: 0;
            pointer-events: none;
          }
          // &:hover {
          //   right: 110px;
          //   img:nth-child(2) {
          //     opacity: 1;
          //   }
          // }
        }
        
      }
      div:nth-child(1) {
        position: absolute;
        top: -16px;
        left: 52px;
      }
      div:nth-child(2) {
        position: absolute;
        top: 151px;
        left: -35px;
        span {
          position: absolute;
          right: -480px;
          top: 15px;
          z-index: 2;
          display: inline-block;
          width: 560px;
          height: 124px;
          background: #Fff;
          pointer-events: none;
        }
      }
      div:nth-child(3) {
        position: absolute;
        top: 314px;
        right: 49px;
      }
    }

    .con2 {
      position: absolute;
      top: 50px;
      right: -50px;
      width: 50%;
      div {
        width: 500px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        // background-color: pink;
        .icon {
          position: absolute;
          z-index: 4;
          width: 196px;
          height: 174px;
          cursor: pointer;
          img:nth-child(1) {
            position: absolute;
            top: 1px;
            left: 9px;
            z-index: 3;
            width: 178px;
          }
          img:nth-child(2) {
            display: none;
            position: absolute;
            top: -13px;
            left: 0;
            z-index: 3;
          }
          // span {
          //   position: absolute;
          //   left: -240px;
          //   top: 15px;
          //   z-index: 2;
          //   display: inline-block;
          //   width: 290px;
          //   height: 124px;
          //   background: #fff;
          //   pointer-events: none;
          // }
          &:hover~.text {
            opacity: 1;
            position: absolute;
            left: 40px;
            img:nth-child(2) {
              animation:move-one 3s 0s infinite;
            }
          }
          &:hover {
            img:nth-child(2) {
              display: block;
            }
          }
        }
        .text {
          width: 426px;
          position: absolute;
          left: -239px;
          top: 21px;
          transition: left 0.5s;
          // display: none;
          z-index: 2;
          opacity: 0;
          img:nth-child(1) {
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
          }
          img:nth-child(2) {
            position: absolute;
            top: -55px;
            right: 0;
            z-index: 1;
            transform: rotate(30deg);
          }
        }
        
      }
      div:nth-child(1) {
        position: absolute;
        top: -16px;
        left: 52px;
      }
      div:nth-child(2) {
        position: absolute;
        top: 151px;
        right: -35px;
      }
      div:nth-child(3) {
        position: absolute;
        top: 314px;
        right: 49px;
      }
    }
  }

@keyframes move-one {
  0% {
    transform:rotate(25deg);
  }
  25% {
    transform:rotate(35deg);
  }
  50% {
    transform:rotate(25deg);
  }
  75% {
    transform:rotate(35deg);
  }
  100% {
    transform:rotate(25deg);
  }
}
@keyframes move-two {
  0% {
    transform:rotate(-25deg);
  }
  25% {
    transform:rotate(-35deg);
  }
  50% {
    transform:rotate(-25deg);
  }
  75% {
    transform:rotate(-35deg);
  }
  100% {
    transform:rotate(-25deg);
  }
}


  // .con {
  //   max-width: 1200px;
  //   margin: 30px auto 0;
  //   text-align: start;
  //   .col-6 {
  //     position: relative;
  //   }
  // }
  // .box {
  //   height: 160px;
  //   border-radius: 20px;
  //   padding-left: 20px;
  //   padding-right: 10px;
  //   padding-top: 27px;
  //   cursor: pointer;
  //   display: flex;
  //   align-items: flex-start;
  //   position: relative;
  //   vertical-align: middle;
  //   background: none;
  //   box-sizing: border-box;
  //   border: 1px solid #fff;
  //   img {
  //     margin-right: 18px;
  //     width: 100px;
  //   }
  //   p:nth-child(1) {
  //     padding-top: 10px;
  //     font-size: 24px;
  //     font-weight: 400;
  //     margin-bottom: 14px;
  //   }
  //   p:nth-child(2) {
  //     font-size: 16px;
  //     line-height: 20px;
  //     font-weight: 400;
  //   }

  //   &:hover {
  //     box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
  //     border-radius: 30px;
  //     border: 1px solid #bed3ff;
  //   }
  //   &::before,
  //   &::after {
  //     box-sizing: inherit;
  //     content: '';
  //     position: absolute;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 30px;
  //   }
  // }
  
}

.warp2 {
  margin-top: -20px;
  .public-title {
    margin-top: 40px;
  }
  .g-tabs {
    margin-top: 20px;
  }
  .box {
    width: 1000px;
    margin: 40px auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    margin-right: 55px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
    }
    .text {
      font-size: 14px;
      line-height: 26px;
      color: #666;
    }
  }

}

.warp3 {
  margin-top: 15px;
  .container {
    margin-top: 105px;
    width: 1200px;
  }
  .box {
    position: relative;
    width: 260px;
    height: 320px;
    padding-top: 50px;
    border-radius: 70px 0px 70px 0px;
    border: 0;
    box-sizing: border-box;
    position: relative;
    vertical-align: middle;
    background: none;
    span {
      position: absolute;
      top: -25%;
      left: 50%;
      transform: translate(-50%, 10%);
      display: inline-block;
      width: 100px;
      height: 100px;
      background: linear-gradient(135deg, #6CAFFE, #6B85F1);
      box-shadow: 0px 10px 20px 0px #6B8BF4;
      border-radius: 50%;
      font-size: 20px;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 2px;
      line-height: 25px;
    }
    img {
      margin-top: -50px;
      width: 262px;
      height: 232px;
    }
    p {
      width: 80%;
      margin: 0 auto;
      text-align: center;
      line-height: 26px;
    }

    &::before,
    &::after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 70px 0px 70px 0px;
    }
    &:hover {
      box-shadow: 0px 10px 20px 0px rgba(0, 60, 255, 0.1);
    }
  }

  .box2 span  {
    background: linear-gradient(135deg, #FFF177, #FFB049);
    box-shadow: 0px 10px 20px 0px #FFE1A2;
  }
  .box3 span {
    background: linear-gradient(135deg, #6CFEBF, #6BB4F1);
    box-shadow: 0px 10px 20px 0px #9BFFF2;
  }
  .box4 span {
    background: linear-gradient(135deg, #FF80A1, #CB5CE7);
    box-shadow: 0px 10px 20px 0px #FFA8EC;
  }

}

.warp4 {
  margin-top: 50px;
  width: 100%;
  background: #f5f8fd;
  .public-title {
    margin-top: 60px;
  }
  .box {
    width: 1200px;
    min-height: 300px;
    margin: -20px auto 10px;
  }
}

.warp5 {
  width: 100%;
  padding-bottom: 50px;
  .v-responsive {
    overflow: auto;
  }
  .con {
    max-width: 1200px;
    margin: 0px auto 10px;
    text-align: start;
  }
  .box {
    padding: 30px 180px;
    height: 240px;
    margin-top: 25px;
    cursor: pointer;
    background: #f5f8fd;
    // background: skyblue;
    border-radius: 30px;
    img {
      position: absolute;
      top: 0px;
      left: 170px;
      width: 280px;
      height: 280px;
      border-radius: 30px;
    }
    div {
      padding-left: 310px;
      p:nth-of-type(1) {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }
      p:nth-of-type(2) {
        margin: 12px 0 26px;
        font-size: 16px;
        white-space:nowrap;
        color: #333333;
      }
      p:nth-of-type(3) {
        font-size: 13px;
        color: #666;
        line-height: 24px;
      }
    }
  }
  
  
}
</style>
